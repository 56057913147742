.root {
    .mainContainer {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        justify-content: space-around;
        & > div {
            flex: 0 0 300px;
            margin: 20px 0;
        }
    }
    .cardsRoot {
        .cardRoot {
            content-visibility: auto;
            color: #29335c;
            background-color: #ffe082;
            margin: 0px auto 20px;
            width: clamp(300px, 100%, 600px);
            :global(.MuiCardHeader-root) {
                text-align: center;
            }
            :global(.MuiCardContent-root) {
                min-height: 210px;
                & img {
                    border: 2px solid #fff;
                }
            }
            :global(.MuiCardActions-root) {
                justify-content: center;
            }
            :global(.MuiCardHeader-content span) {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2rem;
                // color: #fff;
            }
        }

        .cardContent {
            padding: 8px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: space-evenly;
            row-gap: 6px;
            grid-gap: 6px;
            background-color: #fff;
            margin: 0 5px;
            border-radius: 6px;
            & > div {
                text-align: center;
                display: flex;
                flex-direction: column;
            }
        }

        .subtitle {
            font-size: 0.65rem;
            font-weight: bold;
            position: relative;
            top: -6px;
        }
    }
}
